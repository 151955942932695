<template lang="pug">
  form.mt-24(@submit.prevent="submit")
    .flex.justify-between.w-full.border-b.border-black
      //- after submit
      template(v-if="status")
        .h-36.cursor-pointer.flex.items-center(@click="reset")
          span(v-if="status === 'success'") Thank you for subscribing
          span(v-if="status === 'exists'") Aleady subscribed!
          span(v-if="status === 'error'") Sorry, an error occurred. Please try again.
      //- input
      template(v-else)
        input.h-36.flex-1.flex.items-center(ref="input", v-model="formData.email", type="email", name="email", required, placeholder="Your Email Here")
        button.pl-24(type="submit", aria-label="Submit", :class="{'invisible': !formData.email.length}")
          img.select-none.block(src="@/assets/icons/arrow-right.svg")
</template>

<script>
export default {
  name: 'SubscribeForm',
  data () {
    return {
      status: null,
      formData: {
        email: ''
      }
    }
  },
  methods: {
    submit () {
      this.$store.dispatch('addSubscriber', this.formData)
        .then(resp => {
          // console.log(resp)
          this.status = resp.status === 200 ? 'success'
            : resp.body.title === 'Member Exists' ? 'exists'
              : 'error'
        })
        .catch((err) => {
          this.status = 'error'
          console.error(err)
        })
    },
    reset () {
      this.status = null
      this.formData.email = ''
      this.$nextTick(() => this.focusInput())
    },
    focusInput () {
      this.$refs.input.focus()
    }
  }
}
</script>

<style>
</style>
