<template lang="pug">
  page-layout.text-13.md-text-16(v-if="doc")
    //- header
    prismic-rich-text.reader-show(slot="header", :field="doc.title")
    //- slices...
    template(v-for="(slice, i) in doc.body")
      //- (scroll pt)
      template(v-if="slice.slice_type === 'scroll_point'")
        .-mt-78.md--mt-144.pb-78.md-pb-144.scroll-pt(:id="idy(txt(slice.primary.label))", ref="scrollpt")

      //- (text)
      template(v-if="slice.slice_type === 'text'")
        section.mb-78.md-mb-144.px-12
          prismic-rich-text.sm-w-12x18.lg-w-8x18.px-12.mx-auto.text-13.md-text-16.child-p-mb-em.child-h4-font-medium(:field="slice.primary.text")

      //- (subscribe)
      template(v-else-if="slice.slice_type === 'subscribe_form'")
        section.mb-78.md-mb-144.px-12
          .sm-w-12x18.lg-w-8x18.px-12.mx-auto
            h4.font-medium {{ txt(slice.primary.heading) }}
            prismic-rich-text(:field="slice.primary.lead")
            subscribe-form.mt-24
            //- form.mt-24
              .h-36.border-b.border-black.flex.items-center.w-full Your Email Here

      //- (cv : section)
      template(v-else-if="slice.slice_type === 'cv_group'")
        section.px-12
          .sm-w-12x18.lg-w-8x18.px-12.mx-auto.mb-em
            //- header
            .w-grid-24.-ml-24.px-12.flex.justify-end.flex-wrap
              prismic-rich-text.w-14x18.md-w-7x8.mb-em.px-12.font-medium(v-if="slice.primary.heading[0]", :field="slice.primary.heading")
            //- body
            .w-grid-24.-ml-24.px-12.flex.w-full.justify-end(v-for="item in slice.items")
              //- year
              .block.w-4x18.md-w-1x8.px-12 <h6>{{ item.year }}</h6>
              //- text
              prismic-rich-text.w-14x18.md-w-7x8.px-12(:field="item.text")

      //- (press)
      template(v-else-if="slice.slice_type === 'press_list'")
        section.mb-78.md-mb-144.px-12
          prismic-rich-text.sm-w-12x18.lg-w-10x18.mx-auto.px-12.hover-text-gray-500.font-medium.child-links-hover-black.trans-color-fast.leading-looser(:field="slice.primary.text")
</template>

<script>
import store from '@/store'
import { getNav, idy } from '@/utils'
import PageLayout from '@/components/PageLayout'
import SubscribeForm from '@/components/SubscribeForm'
export default {
  name: 'Page',
  props: ['uid', 'entering'],
  data () {
    return {
      idy,
      txt: this.$prismic.richTextAsPlain
    }
  },
  computed: {
    doc () {
      const doc = this.$store.state.pages.find(doc => doc.uid === this.uid)
      return doc && doc.data
    }
  },
  methods: {
    scrollToSection (hash) {
      if (!this.$refs.scrollpt) return
      if (!hash) return this.$scrollTo('#app')
      const sec = this.$refs.scrollpt.find(pt => pt.id === hash.replace('#', ''))
      return sec && this.$scrollTo(sec)
    }
  },
  watch: {
    '$route' (to) {
      this.scrollToSection(to.hash)
    },
    entering (enter) {
      if (!enter) setTimeout(() => this.scrollToSection(this.$route.hash), 50)
    }
  },
  beforeRouteEnter: (to, from, next) => getPage(to, from, next),
  beforeRouteUpdate: (to, from, next) => getPage(to, from, next),
  beforeRouteLeave: (to, from, next) => {
    store.commit('SET_NAV')
    next()
  },
  components: { PageLayout, SubscribeForm }
}

const getPage = (to, from, next) => {
  if (to.name === from.name && to.hash) return next()
  store.commit('PROGRESS_START')
  store.dispatch('getPage', to.params.uid).then(doc => {
    if (!doc) return store.commit('PROGRESS_FAIL') // TODO - maybe router.push 404 ?
    store.commit('PROGRESS_FINISH')
    store.commit('SET_NAV', getNav(doc.data.body))
    next()
  })
}
</script>

<style>
</style>
