<template lang="pug">
  article.pt-72.md-pt-144.leading-wide.min-h-screen.flex.flex-col
    header
      slot(name="header")
    section.flex-1.pb-72.md-pb-144
      slot

    //- footer
</template>

<script>
export default {
  name: 'PageLayout'
}
</script>

<style>
</style>
